import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import Home from './Home';
import { useShopify } from '../hooks';
import { useCollection } from '../context/collection';
import createTheme from '../theme/material-ui';
import NotFound from './NotFound';
import ProductLanding from './ProductLanding';
import Shirts from './Shirts';
import Hoodies from './Hoodies';
import Accessories from './Accessories';
import Lottie from 'react-lottie-player';
import lottieJson from '../images/bg-animation.json';

const App = () => {
	const { createShop, createCheckout, fetchCollection } = useShopify();
	const { shopifyID, accentColor, accentColorLuminance } = useCollection();

	const theme = useMemo(() => createTheme(accentColor, accentColorLuminance), [
		accentColor,
		accentColorLuminance
	]);


	useEffect(() => {
		createShop();
		createCheckout();
		fetchCollection(shopifyID);
	}, [shopifyID]);

	return (
		<ThemeProvider theme={theme}>
			<div id="App">
				<Lottie
					animationData={lottieJson}
					loop={false}
					play
					style={{ width: '100%', display: 'flex', position: 'absolute', zIndex: "-100", padding: "0", margin: "0" }}
				/>
				<Router>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/products" component={Home} />
						<Route exact path="/products/:id" component={ProductLanding} />
						<Route exact path="/latest" component={Home} />
						<Route exact path="/shirts" component={Shirts} />
						<Route exact path="/hoodies" component={Hoodies} />
						<Route exact path="/accessories" component={Accessories} />
						<Route exact path="*" component={NotFound} />
					</Switch>
				</Router>
			</div>
		</ThemeProvider>
	);
};



export default App;