import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Cart from "./Cart";

const NewNav = ({ props }) => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [clothingMobileSubMenuOpen, setClothingMobileSubMenuOpen] =
    React.useState(false);
  const [miscMobileSubMenuOpen, setMiscMobileSubMenuOpen] =
    React.useState(false);

  useEffect(() => {
    setClothingMobileSubMenuOpen(false);
    setMiscMobileSubMenuOpen(false);
  }, [toggleMenu]);

  return (
    <header className="navbar-container">
      <div className="navbar-desktop">
        <div className="navbar-social-links-container">
          <ul className="navbar-social-links">
            <li>
              <a
                href="https://www.youtube.com/c/Enardo"
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i className="fab fa-youtube fa-1x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@EnardoLIVE/videos"
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i className="fa-solid fa-clapperboard fa-1x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.twitch.tv/enardo"
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i className="fab fa-twitch fa-1x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Enardoz"
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i className="fab fa-twitter fa-1x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://discord.com/invite/enardo"
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i className="fab fa-discord fa-1x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://enardoservers.com/"
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i class="fa-solid fa-server"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="navbar-logo-container">
          <NavLink to={"/"}>
            <img
              className="navbar-logo"
              src="/././Assets/logo.png"
              alt="navbar logo"
              width="65px"
              height="77px"
            />
          </NavLink>
        </div>

        <ul className="navbar-buttons-container">
          <div className="navbar-button">
            <NavLink
              to={"/latest"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Latest
            </NavLink>
          </div>
          <div className="navbar-button">
            <NavLink
              to={"/shirts"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Shirts
            </NavLink>
          </div>
          <div className="navbar-button">
            <NavLink
              to={"/hoodies"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Hoodies
            </NavLink>
          </div>
          <div className="navbar-button">
            <NavLink
              to={"/accessories"}
              className={({ isActive }) =>
                isActive
                  ? "navbar-buttons-text link-active"
                  : "navbar-buttons-text"
              }
            >
              Accessories
            </NavLink>
          </div>
          <li
            style={{
              listStyle: "none",
            }}
          >
            <Cart />
          </li>
        </ul>

        <div className="navbar-mobileIcon-container">
          <MenuIcon
            className="navbar-mobile-dropdown"
            fontSize="large"
            onClick={() => setToggleMenu(!toggleMenu)}
          />
        </div>

        {/*--------------------- MOBILE MENU ---------------------*/}

        {toggleMenu && (
          <div className="navbar-mobile-dropdown-container">
            <ul className="navbar-mobile-dropdown-list">
              <NavLink to={"/latest"} className="navbar-buttons-text-mobile">
                <li
                  className="navbar-mobile-dropdown-list-item"
                  onClickCapture={() => setToggleMenu(!toggleMenu)}
                >
                  Latest Release
                </li>
              </NavLink>

              <NavLink to={"/shirts"} className="navbar-buttons-text-mobile">
                <li
                  className="navbar-mobile-dropdown-list-item"
                  onClickCapture={() => setToggleMenu(!toggleMenu)}
                >
                  Shirts
                </li>
              </NavLink>
              <NavLink to={"/hoodies"} className="navbar-buttons-text-mobile">
                <li
                  className="navbar-mobile-dropdown-list-item"
                  onClickCapture={() => setToggleMenu(!toggleMenu)}
                >
                  Hoodies
                </li>
              </NavLink>
              <NavLink to={"/accessories"} className="navbar-buttons-text-mobile">
                <li
                  className="navbar-mobile-dropdown-list-item"
                  onClickCapture={() => setToggleMenu(!toggleMenu)}
                >
                  Accessories
                </li>
              </NavLink>
            </ul>
          </div>
        )}
      </div>

      {/* Mobile */}
      <div className="navbar-mobile"></div>
    </header>
  );
};

export default NewNav;
